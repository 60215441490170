/* ConfirmationModal is a reusable modal screen that behaves as a popup on screen larger than 480px 
   and full screen warning on smaller devices. It takes body, header and button 
   text with all their callbacks as props */

import React from 'react';

import { OptionButtonsType } from '@interstate/components/ActionButtons';
import {
  ConfirmationModalStyledComponent,
  ModalBody,
  ModalHeaderContainer,
  ModalText
} from './ConfirmationModalStyle';

interface ConfirmationModalProps {
  modalHeader?: string;
  modalBody: string | React.ReactNode;
  confirmationText: string;
  cancelText: string;
  showModal: boolean;
  onContinue: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalHeader,
  modalBody,
  confirmationText,
  cancelText,
  showModal,
  onContinue,
  onCancel
}: ConfirmationModalProps) => {
  const OptionalButtons: OptionButtonsType = cancelText
    ? [
        {
          action: onCancel,
          label: cancelText,
          'data-testid': 'cancel-btn-testid'
        }
      ]
    : [];

  return (
    <ConfirmationModalStyledComponent
      show={showModal}
      data-testid='confirmation-modal-testid'
      header={
        modalHeader && (
          <ModalHeaderContainer>
            <ModalText variant='h3'>{modalHeader}</ModalText>
          </ModalHeaderContainer>
        )
      }
      footer={{
        options: OptionalButtons,
        primary: {
          action: onContinue,
          label: confirmationText
        }
      }}
      id='confirmation-modal'
      size='small'
      padding={false}
    >
      <React.Fragment>
        <ModalBody className='modal-body'>
          {typeof modalBody === 'string' ? (
            <>
              <ModalText variant='body-md'>{modalBody}</ModalText>
              <br />
            </>
          ) : (
            modalBody
          )}
        </ModalBody>
      </React.Fragment>
    </ConfirmationModalStyledComponent>
  );
};

export default ConfirmationModal;
