import ReactDOM from 'react-dom/client';

import 'index.css';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { loadConfig } from 'utils/configLoader';
import CertAssistApp from 'CertAssistApp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

(async function initializeApp() {
  try {
    // Load configuration before rendering the app
    await loadConfig();

    // Once the config is loaded, render the CertAssistApp
    root.render(
      <BrowserRouter>
        <CertAssistApp />
      </BrowserRouter>
    );
  } catch (error) {
    console.error('Failed to load configuration', error);
    // Optionally, you can display an error message or render an error page here
  }
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
