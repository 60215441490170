import { styled } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';

export const LineSeperatorHrBox = styled(Box)`
  && {
    padding: 0px 0px 0px 0px;
  }
`;

export const LineSeperatorHr = styled(Typography)`
  flex-grow: 1;
`;
