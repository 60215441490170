import { configureStore } from '@reduxjs/toolkit';
import recentScansReducer from 'store/slice/recentScans/recentScansSlice';
import locationReducer from 'store/slice/locations/locationSlice';
import vehicleReducer from 'store/slice/vehicle/vehicleSlice';
import certificationReducer from 'store/slice/certification/certificationSlice';

// Setup Redux store with reducers
const store = configureStore({
  reducer: {
    location: locationReducer,
    vehicle: vehicleReducer,
    recentScans: recentScansReducer,
    certification: certificationReducer
  }
});

// Define types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
