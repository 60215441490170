import { styled } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';

export const InspectionNotesComponentBox = styled(Box)`
  && {
    padding: 0px 0px 0px 0px;
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const InspectionNotesTopBox = styled(Box)`
  && {
    padding: 0px 27px 0px 27px;
    width: 100%;
  }
`;

export const InspectionNotesTitleBox = styled(Box)`
  && {
    padding-bottom: 9px;
  }
`;

export const InspectionNotesTitleText = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 16px;
    color: #25292e;
  }
`;

export const InspectionNotesBox = styled(Box)`
  && {
    padding: 14px 0px 0px 0px;
  }
`;

export const AddNewNoteText = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    color: #6d6d6d;
  }
`;

export const InspectionNotesTextAreaBox = styled(Box)`
  && {
    padding: 4px 0px 0px 0px;
  }
`;

export const RemainingCharacterText = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    color: #6d6d6d;
  }
`;

export const ActionButtonsBox = styled(Box)`
  display: flex;
  position: sticky;
  bottom: 0px;
  background-color: #f4f4f4;
`;

export const SaveButton = styled(Button)`
  && {
    width: 50%;
    margin: 15px 8px 15px 15px;
  }
`;

export const FinishButton = styled(Button)`
  && {
    width: 50%;
    margin: 15px 15px 15px 8px;
    background-color: #ffc20e;
    color: black;
  }
`;
