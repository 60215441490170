import { postCertificationData } from 'services/certification/certificationService';
import { CertData } from 'services/certification/certification.types';
import { LocalStorageKeys } from 'constants/constants';

interface PostAndNavigateParams {
  certData: CertData;
  status: string;
  locationCode?: string;
  options: any[];
  setLoading: (loading: boolean) => void;
  setHasChanges: (hasChanges: boolean) => void;
  setShowAlert: (showAlert: boolean) => void;
  setAlertMessage: (message: string | null) => void;
  onSuccess?: () => void;
}

export const postAndNavigate = async ({
  certData,
  status,
  locationCode,
  options,
  setLoading,
  setHasChanges,
  setShowAlert,
  setAlertMessage,
  onSuccess
}: PostAndNavigateParams): Promise<boolean> => {
  setLoading(true);

  const endTime = new Date().toISOString();

  const updatedCertification = { ...certData.certification, endTime: endTime };

  const updatedCertData = {
    ...certData,
    processStatus: status,
    certification: JSON.stringify(updatedCertification)
  };

  try {
    await postCertificationData(updatedCertData);
    setHasChanges(false);
    setShowAlert(false);

    if (locationCode) {
      const locationObj = options.find(
        (option) => option.locationCode === locationCode
      );
      if (locationObj) {
        const locationLabel = `${locationObj.locationName} - ${locationObj.locationCode}`;
        localStorage.setItem(LocalStorageKeys.LOCATION, locationLabel);
      }
    }

    if (onSuccess) onSuccess();
    return true;
  } catch (error) {
    console.error('Error posting updated certData:', error);
    setAlertMessage('Failed to store answers. Please try again.');
    setShowAlert(true);
    return false;
  } finally {
    setLoading(false);
  }
};
