import { styled } from 'styled-components';
import { Modal } from '@interstate/components/Modal';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';

export const ModalBody = styled(Box)`
  margin: 0px 24px;

  @media (max-width: 479px) {
    margin: 0px 16px;
  }
`;
export const ModalHeaderContainer = styled(Box)`
  font-size: 18px;
  font-weight: 500;
`;

export const ModalText = styled(Typography)`
  && {
    &.confirmationModal-content {
      .MuiAlert-message {
        .MuiAlertTitle-root {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 500;
        }
      }
      .confirmation-msg {
        margin-top: 25px;
      }
    }
  }
`;

export const ConfirmationModalStyledComponent = styled(Modal)`
  .MuiPaper-root.MuiDialog-paperWidthSm.MuiDialog-paper {
    max-width: 640px;
  }

  #ids-modal-close-button-confirmation-modal {
    display: none !important;
  }

  .ids-modal-footer #ids-action-button-option0-confirmation-modal {
    background-color: #005ba8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }

  .ids-modal-footer #ids-action-button-option0-confirmation-modal:hover {
    background-color: #1976d2;
  }

  #ids-action-button-primary-confirmation-modal {
    background-color: #ffc20e;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;
  }

  @media (max-width: 479px) {
    #ids-modal-title-confirmation-modal {
      padding: 16px 16px 20px 16px;
    }

    .ids-modal-footer #ids-action-button-option0-confirmation-modal,
    #ids-action-button-primary-confirmation-modal {
      font-size: 14px;
      padding: 0 8px;
    }

    .ids-modal-footer.css-gnbd3q {
      padding-right: 16px;
    }
  }
`;
