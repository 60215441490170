import { styled } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';

export const HomePage = styled(Box)``;

export const ConfirmationModalContainer = styled(Box)`
  .MuiAlert-message .MuiAlertTitle-root {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  @media (max-width: 479px) {
    .MuiAlert-message .MuiAlertTitle-root {
      font-size: 16px;
    }
  }
`;

export const ConfirmationModalAlert = styled(Alert)``;

export const AlertBoxText = styled(Typography)`
  @media (max-width: 479px) {
    font-size: 14px !important;
  }
`;

export const AlertBoxWarningMessage = styled(Typography)`
  .confirmationModal-content & {
    margin-top: 25px;
  }
  @media (max-width: 479px) {
    .MuiAlert-message & {
      font-size: 14px;
    }
  }
`;
