import { styled } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';

export const ConfirmationModalContainer = styled(Box)``;

export const ConfirmationModalAlert = styled(Alert)``;

export const AlertBoxText = styled(Typography)`
  @media (max-width: 479px) {
    font-size: 14px !important;
  }
`;

export const CertButtonContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 13px;
    text-align: center;
    position: sticky;
    bottom: 0;
    background-color: #f4f4f4;
  }
`;

export const CertButtonSave = styled(Button)`
&& {
  width: 50%;
`;

export const CertButtonFinish = styled(Button)`
&& {
  width: 50%;
  background-color: #ffc20e !important;
  color: black !important;
`;

export const ErrorTypography = styled(Typography)`
&& {
  text-align: center;
  padding: 15px;
`;

/* FQE UI Changes */
export const CertificationContainer = styled(Box)`
  #cert-assist-questions-and-announcements-root p:first-child {
    padding-left: 27px;
    font-size: 20px;
    font-weight: 600;
  }

  /* FQE Container */
  .Question__Container-qjdhjw-9 {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 27px;
    margin-right: 27px;
    background-color: #ebf6ff;
  }

  /* FQE question Buttons */
  .Question__QuestionText-qjdhjw-7,
  .AnswerLabel__ButtonLabel-sc-1pvwchk-3,
  .AnswerNotes__NotesInput-vofv4e-2,
  .AnswerButton-sc-1ymy5p1-0 {
    font-size: 16px;
  }

  .Question__QuestionText-qjdhjw-7 {
    font-weight: 700;
    padding-left: 2px;
  }

  .AnswerLabel__ButtonLabel-sc-1pvwchk-3,
  .AnswerButton-sc-1ymy5p1-0 {
    font-weight: 600;
  }

  .AnswerNotes__NotesInput-vofv4e-2 {
    font-weight: 400;
  }

  /* Inner Title of dropdown */
  .NestedAnswers__NestedQuestionText-sc-1nze12h-5 {
    color: #005ba8 !important;
  }

  /* Inner Dashed line of dropdown */
  .gOBStz.active {
    border-top: 1px dashed #000;
  }

  /* Chevron svg of dropdown */
  .NestedAnswers__ButtonContainer-sc-1nze12h-3 svg path {
    fill: #333;
  }

  .NestedAnswers__TakePhotoContainer-sc-1nze12h-4 button.active {
    background-color: #ebf6ff;
    color: #005ba8;
  }
`;
