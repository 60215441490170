import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { saveScanData } from 'services/recentScan/recentScanService';
import { RecentScan, Scan } from 'services/recentScan/recentScan.types';
import { MFPayload } from 'models/MFPayload';

import {
  VehicleInfoData,
  VehicleInfoProps,
  VehicleInfoUI
} from '@checkpoint/common-components';
import { Vehicle } from 'store/slice/vehicle/vehicle.types';
import { VehicleTab } from './VehicleStyle';
import { STRING_CONSTANTS } from '../../constants/constants';
import {
  AlertComponent,
  AlertSeverity
} from 'reusableComponents/alertComponent/AlertComponent';

/**
 * Function to format the number
 * @param number
 */
const formatNumber = (number: number) => {
  return number.toLocaleString();
};

/**
 * Vehicle Props
 */
type VehicleProps = MFPayload & {
  locationCode?: string;
  onContinue: () => void;
  onBack: () => void;
  vehicleData: Vehicle | null;
};

/**
 * Function to format the units to title case
 * @param units
 */
const formatToTitleCase = (units: string): string => {
  return units
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

enum AlertType {
  NoCertOrdered,
  PreviouslyInspected
}

/**
 * Vehicle Info Component
 * @param props
 * @constructor
 */
const VehicleInfo: React.FC<VehicleProps> = (props) => {
  const [showAlert, setShowAlert] = useState<AlertType | null>(null);
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);

  const userId = props.userDetails?.userId;
  const { locationCode } = props;

  useEffect(() => {
    const vehicle = vehicleState;

    if (
      vehicle?.certificationDetails?.status &&
      !['REQUESTED', 'CANT_DO', 'DECLINED'].includes(
        vehicle?.certificationDetails?.status
      )
    ) {
      setShowAlert(AlertType.PreviouslyInspected);
    }
    if (!vehicle?.certificationDetails) {
      setShowAlert(AlertType.NoCertOrdered);
    }
  }, [vehicleState]);

  /**
   * Function to create scan data
   */
  function createScanData(): string {
    const vehicle = vehicleState;
    if (vehicle && userId) {
      const scan: Scan = {
        userId: userId,
        locationCode: locationCode,
        consignmentId: vehicle.consignmentId,
        workOrderNumber: vehicle.workOrderNumber,
        vin: vehicle.vin,
        shortDescription: vehicle.shortDescription,
        exteriorNormalizedName: vehicle.exteriorNormalizedName,
        interiorNormalizedName: vehicle.interiorNormalizedName
      };
      return JSON.stringify(scan);
    }
    return '';
  }

  // Save last scanned record to DB on page load.
  useEffect(() => {
    if (vehicleState && userId) {
      const saveRecentScan = async () => {
        try {
          const currentDate = new Date();
          const lastScannedTime = currentDate.toISOString();
          const params: RecentScan = {
            pk: userId,
            consignmentId: vehicleState?.consignmentId!,
            lastScannedTime: lastScannedTime,
            scanData: createScanData()
          };
          await saveScanData(params);
        } catch (err) {
          console.error(err);
        }
      };
      saveRecentScan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleState, userId, locationCode]);

  function handleContinueClick() {
    props.onContinue();
  }

  function handleBackClick() {
    props.onBack();
  }

  const vehicleInfo: VehicleInfoData = {
    workOrderNumber: vehicleState?.workOrderNumber,
    companyName: vehicleState?.companyName,
    manheimAccountNumber: vehicleState?.manheimAccountNumber,
    vin: vehicleState?.vin,
    reading: vehicleState?.reading ? formatNumber(vehicleState?.reading) : '',
    units: vehicleState?.units ? formatToTitleCase(vehicleState?.units) : '',
    shortDescription: vehicleState?.shortDescription,
    interiorColor: vehicleState?.interiorNormalizedName,
    exteriorColor: vehicleState?.exteriorNormalizedName,
    certificationDetails: vehicleState?.certificationDetails
  };

  const vehicleInfoProps: VehicleInfoProps = {
    vehicleInfo: vehicleInfo,
    onContinue: handleContinueClick,
    onBack: handleBackClick
  };

  return (
    <VehicleTab>
      {showAlert === AlertType.PreviouslyInspected && (
        <AlertComponent
          title={STRING_CONSTANTS.PREVIOUSLY_INSPECTED}
          subtitle={`by ${vehicleState?.certificationDetails?.updatedBy} on ${vehicleState?.certificationDetails?.updatedOn}`}
          alertSeverity={AlertSeverity.Caution}
          testId={'alert-box-text'}
        />
      )}
      {showAlert === AlertType.NoCertOrdered && (
        <AlertComponent
          title={STRING_CONSTANTS.NO_CERT_ORDERED}
          subtitle={STRING_CONSTANTS.NO_CERT_ORDERED_MESSAGE}
          alertSeverity={AlertSeverity.Error}
          testId={'alert-box-text'}
        />
      )}

      <VehicleInfoUI {...vehicleInfoProps} />
    </VehicleTab>
  );
};
export default VehicleInfo;
