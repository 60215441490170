import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CertData,
  QnaData,
  QuestionResponse
} from 'services/certification/certification.types';

const initialCertData: CertData = {
  consignmentId: 'N/A',
  processStatus: '',
  certification: {
    startTime: new Date().toISOString(),
    endTime: '',
    locationCode: undefined,
    workOrderNumber: 'N/A',
    manheimAccountNumber: 'N/A',
    vin: 'N/A',
    userId: 'N/A',
    consignmentHref: 'N/A',
    unitHref: 'N/A',
    qnaData: {
      valid: false,
      prompting: false,
      questionResponses: [],
      announcements: [],
      totalQuestions: 0,
      questionnaireHref: '',
      metaData: []
    },
    notes: ''
  }
};

const certificationSlice = createSlice({
  name: 'certification',
  initialState: {
    certData: initialCertData,
    hasChanges: false,
    initialResponses: [] as QuestionResponse[],
    initialNotes: '',
    safetoInspect: true,
    showModal: false
  },
  reducers: {
    setCertData: (state, action: PayloadAction<CertData>) => {
      state.certData = action.payload;
    },
    updateCertData: (state, action: PayloadAction<Partial<CertData>>) => {
      state.certData = { ...state.certData, ...action.payload };
    },
    updateQnaData: (state, action: PayloadAction<QnaData>) => {
      state.certData.certification.qnaData = action.payload;
    },
    setInitialResponses: (state, action: PayloadAction<any[]>) => {
      state.initialResponses = action.payload;
    },
    setHasChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload;
    },
    setInitialNotes: (state, action: PayloadAction<string>) => {
      state.initialNotes = action.payload;
    },
    computeHasChanges: (state) => {
      const initialResponses = state.initialResponses || [];
      const currentResponses =
        state.certData.certification.qnaData.questionResponses || [];
      const initialNotes = state.initialNotes;
      const currentNotes = state.certData.certification.notes;

      const isNotesChange = initialNotes !== currentNotes;

      const hasLengthChange =
        initialResponses.length !== currentResponses.length;

      const isValueChange = initialResponses.some((row: any, i: number) => {
        if (row?.answers && row.answers.length > 0) {
          const currentResponse = currentResponses[i];
          if (
            !currentResponse ||
            !currentResponse.answers ||
            currentResponse.answers.length === 0
          ) {
            return false;
          }
          if (
            row.answers[0]['value'] &&
            row.answers[0].value !== currentResponse.answers[0].value
          ) {
            return true;
          }
          if (row.answers[0].notes !== currentResponse.answers[0].notes) {
            return true;
          }
        }
        return false;
      });

      state.hasChanges = isNotesChange || hasLengthChange || isValueChange;
    },
    computeShowModal: (state) => {
      const initialResponses = state.initialResponses;
      const currentResponses =
        state.certData.certification.qnaData.questionResponses || [];
      const metaData = state.certData.certification.qnaData.metaData || [];
      const safetoInspect = !metaData.some(
        (meta: any) => meta.metaData?.safetoInspect === false
      );

      const wasSafeInitially = !initialResponses.some((row: any) =>
        metaData.some(
          (meta: any) =>
            meta.value === row?.answers?.[0]?.value &&
            meta.metaData?.safetoInspect === false
        )
      );

      const isUnsafeChange = !initialResponses
        ? false
        : initialResponses.length === 0
          ? currentResponses.some((resp) =>
              resp.values?.some((val: any) =>
                metaData.some(
                  (meta: any) =>
                    meta.value === val && meta.metaData?.safetoInspect === false
                )
              )
            )
          : (wasSafeInitially && !safetoInspect) ||
            initialResponses.some((row: any, i: number) => {
              const initialValue = row?.answers?.[0]?.value;
              const currentValue = currentResponses[i]?.answers?.[0]?.value;
              if (
                initialValue &&
                currentValue &&
                initialValue !== currentValue
              ) {
                const isCurrentUnsafe = metaData.some(
                  (meta: any) =>
                    meta.value === currentValue &&
                    meta.metaData?.safetoInspect === false
                );
                return isCurrentUnsafe;
              }
              return false;
            });

      state.safetoInspect = safetoInspect;
      state.showModal = !safetoInspect && isUnsafeChange;
    },
    syncInitialState: (state) => {
      state.initialResponses = [
        ...(state.certData.certification.qnaData.questionResponses || [])
      ];
      state.initialNotes = state.certData.certification.notes;
      state.hasChanges = false;
      state.safetoInspect = true;
      state.showModal = false;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    }
  }
});

export const {
  setCertData,
  updateCertData,
  updateQnaData,
  setInitialResponses,
  setHasChanges,
  setInitialNotes,
  computeHasChanges,
  computeShowModal,
  syncInitialState,
  setShowModal
} = certificationSlice.actions;
export default certificationSlice.reducer;
