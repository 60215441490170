import { Box } from '@interstate/components/Box';
import { COLORS, STRING_CONSTANTS } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { Interstitial } from '@interstate/components/Interstitial';
import { MFPayload } from 'models/MFPayload';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import {
  QnaData,
  QuestionResponse
} from 'services/certification/certification.types';
import { postAndNavigate } from './certificationUtils';
import {
  computeHasChanges,
  setHasChanges,
  setInitialResponses,
  setInitialNotes,
  updateCertData,
  updateQnaData,
  syncInitialState,
  computeShowModal,
  setShowModal
} from 'store/slice/certification/certificationSlice';

import ConfirmationModal from 'reusableComponents/confirmationModal/ConfirmationModal';
import {
  AlertBoxText,
  CertButtonContainer,
  CertButtonFinish,
  CertButtonSave,
  CertificationContainer,
  ConfirmationModalAlert,
  ConfirmationModalContainer,
  ErrorTypography
} from './certificationStyle';
import {
  AlertComponent,
  AlertSeverity
} from 'reusableComponents/alertComponent/AlertComponent';

declare global {
  interface Window {
    QuestionsAndAnnouncements?: any;
    NativeApp?: {
      showAlertForMenuSwitch: (showAlert: boolean, message: string) => void;
    };
  }
}
interface ConfirmationModalBodyProps {
  showAlert: boolean;
  alertMessage: any;
}

type CertificationProps = MFPayload & {
  locationCode?: string;
  onContinue: () => void;
  onFinish: () => void;
  initialCertificationData?: any;
};

const Certification: React.FC<CertificationProps> = ({
  locationCode,
  onContinue,
  onFinish,
  initialCertificationData,
  ...certificationProps
}: CertificationProps) => {
  const [loading, setLoading] = useState(true);
  const [savingLoading, setSavingLoading] = useState(false);
  const [qaAvailable, setQaAvailable] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { certData, hasChanges, safetoInspect, showModal } = useSelector(
    (state: RootState) => state.certification
  );
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);
  const options =
    useSelector((state: RootState) => state.location.data?.locations) || [];

  const toUpperCase = (value: any): any => {
    return value ? value.toUpperCase() : undefined;
  };

  useEffect(() => {
    dispatch(
      updateCertData({
        consignmentId: vehicleState?.consignmentId || 'N/A',
        certification: {
          ...certData.certification,
          locationCode,
          workOrderNumber: vehicleState?.workOrderNumber || 'N/A',
          manheimAccountNumber: vehicleState?.manheimAccountNumber || 'N/A',
          vin: vehicleState?.vin || 'N/A',
          userId: toUpperCase(certificationProps.userDetails?.userId || 'N/A'),
          consignmentHref: vehicleState?.consignmentHref || 'N/A',
          unitHref: vehicleState?.unitHref || 'N/A'
        }
      })
    );
    // eslint-disable-next-line
  }, [
    dispatch,
    vehicleState,
    certificationProps.userDetails,
    locationCode,
    certData.certification.qnaData.questionResponses
  ]);

  useEffect(() => {
    if (window.NativeApp) {
      window.NativeApp.showAlertForMenuSwitch(
        true,
        STRING_CONSTANTS.SAVE_INTERCEPT_WARNING_MESSAGE
      );
    }
    return () => {
      window.NativeApp?.showAlertForMenuSwitch(false, '');
    };
  });

  const renderQuestions = (initialQuestionsResponse: QuestionResponse[]) => {
    if (!window.QuestionsAndAnnouncements) {
      console.error('QuestionsAndAnnouncements is not available.');
      setLoading(false);
      setQaAvailable(false);
      return;
    }

    const initialState = {
      announcements: [],
      questionResponses: initialQuestionsResponse
    };

    window.QuestionsAndAnnouncements.renderQuestions({
      onStateChange: (newState: any) => {
        if (loading) {
          setLoading(false);
          setShowButtons(true);
        }

        const questionResponses = newState.questionResponses.map(
          (response: any) => ({
            guid: response.guid,
            answers: response.answers,
            source: response.source,
            values: response.values,
            metadata: {
              updatedBy: toUpperCase(
                certificationProps.userDetails?.userId || 'N/A'
              )
            }
          })
        );

        const qnaData: QnaData = {
          valid: newState.valid,
          prompting: newState.prompting,
          questionResponses,
          announcements: newState.announcements || [],
          totalQuestions: newState.totalQuestions,
          questionnaireHref: newState.questionnaireHref || '',
          metaData: newState.metaData || []
        };

        dispatch(updateQnaData(qnaData));
        dispatch(computeHasChanges());
        dispatch(computeShowModal());

        setShowAlert(false);
        setShowSuccessAlert(false);
      },
      clientApplicationName: STRING_CONSTANTS.APP_NAME,
      rootElementId: 'cert-assist-questions-and-announcements-root',
      environment: certificationProps.appConfig?.qnaEnvironment,
      announcerSource: STRING_CONSTANTS.QE_ANNOUNCER_SOURCE,
      questionnaireHref: certificationProps.appConfig?.certFormUrl,
      initialState: initialState,
      colors: {
        activeButtonBackground: COLORS.LIGHT_SKY_BLUE,
        activeButtonBorder: COLORS.DEEP_BLUE,
        activeButtonText: COLORS.DEEP_BLUE,
        inactiveButtonBackground: COLORS.WHITE,
        inactiveButtonBorder: COLORS.DEEP_BLUE,
        inactiveButtonText: COLORS.DEEP_BLUE,
        progressIndicator: COLORS.DEEP_BLUE
      }
    });
  };

  useEffect(() => {
    if (initialCertificationData) {
      dispatch(
        updateCertData({
          processStatus: initialCertificationData.processStatus || '',
          certification: {
            ...initialCertificationData.certification,
            notes: initialCertificationData.certification.notes || ''
          }
        })
      );
      dispatch(
        setInitialResponses(
          initialCertificationData.certification.qnaData.questionResponses || []
        )
      );
      dispatch(
        setInitialNotes(initialCertificationData.certification.notes || '')
      );

      renderQuestions(
        initialCertificationData.certification.qnaData.questionResponses || []
      );
      dispatch(computeHasChanges());
      dispatch(computeShowModal());
    }
    // eslint-disable-next-line
  }, [initialCertificationData, dispatch]);

  const handleSave = () => {
    postAndNavigate({
      certData,
      status: STRING_CONSTANTS.PARTIAL,
      locationCode,
      options,
      setLoading: setSavingLoading,
      setHasChanges: (value: boolean) => dispatch(setHasChanges(value)),
      setShowAlert,
      setAlertMessage,
      onSuccess: () => {
        dispatch(
          updateCertData({
            processStatus: STRING_CONSTANTS.PARTIAL
          })
        );
        dispatch(syncInitialState());
        setSuccessMessage('Saved vehicle record added to list of recents.');
        setShowSuccessAlert(true);
        setSavingLoading(false);
      }
    });
  };

  const handleConfirm = () => {
    postAndNavigate({
      certData,
      status: STRING_CONSTANTS.SUBMITTED,
      locationCode,
      options,
      setLoading: setSavingLoading,
      setHasChanges: (value: boolean) => dispatch(setHasChanges(value)),
      setShowAlert,
      setAlertMessage,
      onSuccess: () => {
        dispatch(updateCertData({ processStatus: STRING_CONSTANTS.SUBMITTED }));
        dispatch(syncInitialState());
        dispatch(setShowModal(false));
        onFinish();
      }
    });
  };

  const handleCancel = () => {
    dispatch(setShowModal(false));
  };

  useEffect(() => {
    if (showSuccessAlert || showAlert) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [showSuccessAlert, showAlert]);

  return (
    <CertificationContainer>
      <Box id='cert-assist-questions-and-announcements-root'></Box>
      {loading && (
        <Interstitial
          data-testid='loading'
          fullScreen={false}
          size={2}
          message={showButtons ? 'Storing answers' : 'Loading Questions'}
        />
      )}
      {savingLoading && (
        <Interstitial
          data-testid='saving-loading'
          fullScreen={true}
          size={2}
          message='Storing Answers'
        />
      )}
      {showAlert && (
        <AlertComponent
          title={STRING_CONSTANTS.ERROR}
          subtitle={alertMessage ?? ''}
          alertSeverity={AlertSeverity.Error}
          testId={'alert-error'}
        />
      )}
      {showSuccessAlert && (
        <AlertComponent
          title={STRING_CONSTANTS.CHANGES_SAVED}
          subtitle={successMessage ?? ''}
          alertSeverity={AlertSeverity.Success}
          testId={'alert-success'}
        />
      )}
      {showModal && (
        <ConfirmationModal
          modalHeader={STRING_CONSTANTS.CONFIRMATION_REQUIRED}
          modalBody={
            <ConfirmationModalBody
              showAlert={showAlert}
              alertMessage={alertMessage || null}
            />
          }
          confirmationText={STRING_CONSTANTS.CONFIRM}
          cancelText={STRING_CONSTANTS.CANCEL}
          showModal={true}
          onContinue={handleConfirm}
          onCancel={handleCancel}
        />
      )}

      {!qaAvailable ? (
        <ErrorTypography variant={'h1'} data-testid='qe_not_available'>
          {STRING_CONSTANTS.QE_NOT_AVAILABLE}
        </ErrorTypography>
      ) : showButtons ? (
        <CertButtonContainer>
          <CertButtonSave
            buttonStyle='secondary'
            onClick={handleSave}
            disabled={!hasChanges || !safetoInspect}
            className='cert-buttons-save'
          >
            {STRING_CONSTANTS.SAVE}
          </CertButtonSave>
          <CertButtonFinish buttonStyle='primary' onClick={onContinue}>
            {STRING_CONSTANTS.CONTINUE}
          </CertButtonFinish>
        </CertButtonContainer>
      ) : null}
    </CertificationContainer>
  );
};

const ConfirmationModalBody: React.FC<ConfirmationModalBodyProps> = ({
  showAlert,
  alertMessage
}) => {
  return (
    <ConfirmationModalContainer
      className='confirmationModal-content'
      data-testid='confirmation-modal-body'
    >
      <ConfirmationModalAlert type='error'>
        <AlertBoxText variant='body-md'>
          {STRING_CONSTANTS.CERTIFICATION_ALERT_DESC}
        </AlertBoxText>
      </ConfirmationModalAlert>
      {showAlert && (
        <AlertComponent
          title={STRING_CONSTANTS.ERROR}
          subtitle={alertMessage ?? ''}
          alertSeverity={AlertSeverity.Error}
          testId={'alert-error'}
        />
      )}
    </ConfirmationModalContainer>
  );
};

export default Certification;
