import { styled } from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';

export const AlertBox = styled(Box)`
  && {
    padding: 12px 8px 0px 8px;
  }
`;

export const AlertBoxText = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
`;
