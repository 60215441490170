import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from '@interstate/components/Tabs';
import { InformationCircleIcon } from '@interstate/components/Icons/InformationCircleIcon';
import { QuestionMarkCircleIcon } from '@interstate/components/Icons/QuestionMarkCircleIcon';
import { PencilSquareIcon } from '@interstate/components/Icons/PencilSquareIcon';
import { MFPayload } from '../../models/MFPayload';
import { Vehicle } from '../../store/slice/vehicle/vehicle.types';
import VehicleInfo from '../../components/vehicle/Vehicle';
import Certification from '../../components/certification/certification';
import Notes from '../../components/notes/Notes';
import { TabsViewPage } from './TabsViewStyle';

type TabsViewProp = MFPayload & {
  locationCode?: string;
  navigateToHome: () => void;
  vehicleData: Vehicle | null;
  setSuccessMessage?: (message: string | null) => void;
  initialCertificationData?: any;
};

const tabsOptions = [
  { key: '0', name: 'INFO' },
  { key: '1', name: 'CERT' },
  { key: '2', name: 'NOTES' }
];
const TabsView: React.FC<TabsViewProp> = (tabsViewProp) => {
  const [selectTabOption, setSelectTabOption] = useState('INFO');

  const updateActiveTab = useCallback((selectedTab: string) => {
    setSelectTabOption(selectedTab);
  }, []);

  const handleFinish = () => {
    if (tabsViewProp.setSuccessMessage) {
      tabsViewProp.setSuccessMessage(
        'Your answers have been stored successfully.'
      );
    }
    tabsViewProp.navigateToHome();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectTabOption]);

  return (
    <TabsViewPage>
      <Tabs
        id={'certification-tabs-view'}
        data-testid={'certification-tabs-view'}
        onActivate={(tabIndex) => {
          setSelectTabOption(
            tabsOptions[tabIndex]?.name ?? tabsOptions[0]?.name
          );
        }}
        tabs={[
          {
            label: '',
            icon: <InformationCircleIcon />,
            active: selectTabOption === tabsOptions[0].name,
            component: (
              <VehicleInfo
                {...tabsViewProp}
                locationCode={tabsViewProp.locationCode}
                onBack={tabsViewProp.navigateToHome}
                onContinue={() => updateActiveTab(tabsOptions[1].name)}
              />
            )
          },
          {
            label: '',
            icon: <QuestionMarkCircleIcon />,
            active: selectTabOption === tabsOptions[1].name,
            component: (
              <Certification
                {...tabsViewProp}
                locationCode={tabsViewProp.locationCode}
                onContinue={() => updateActiveTab(tabsOptions[2].name)}
                onFinish={handleFinish}
                initialCertificationData={tabsViewProp.initialCertificationData}
              />
            )
          },
          {
            label: '',
            icon: <PencilSquareIcon />,
            active: selectTabOption === tabsOptions[2].name,
            component: (
              <Notes
                {...tabsViewProp}
                locationCode={tabsViewProp.locationCode}
                onFinish={handleFinish}
                onSave={handleFinish}
                setSuccessMessage={tabsViewProp.setSuccessMessage}
              />
            )
          }
        ]}
      />
    </TabsViewPage>
  );
};
export default TabsView;
