import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from 'store/store';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { ActivePage, STRING_CONSTANTS } from 'constants/constants';
import { MFPayload } from 'models/MFPayload';
import { Vehicle } from 'store/slice/vehicle/vehicle.types';
import TabsView from './pages/tabs/TabsView';
import Home from './pages/home/Home';
import { fetchCertificationData } from 'services/certification/certificationService';
import { fetchVehicle, resetVehicle } from 'store/slice/vehicle/vehicleSlice';
import { Interstitial } from '@interstate/components/Interstitial';

type SelectedMenuItem = {
  menuItem: string;
};

const CertAssistApp: React.FC<MFPayload> = (props) => {
  const [activePage, setActivePage] = useState<ActivePage>('HOME');
  const [certAssistData, setCertAssistData] = useState<{
    locationCode?: string;
  }>({});
  const [certificationData, setCertificationData] = useState<any | null>(null);
  const [vehicleData, setVehicleData] = useState<Vehicle | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleNotifySameAppSelection = (
    event: CustomEvent<SelectedMenuItem>
  ) => {
    handleNavigation('HOME', undefined, undefined, true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  useEffect(() => {
    document.addEventListener(
      'onNotifySameAppSelection',
      handleNotifySameAppSelection as EventListener
    );
    return () => {
      store.dispatch(resetVehicle());
      document.removeEventListener(
        'onNotifySameAppSelection',
        handleNotifySameAppSelection as EventListener
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleNavigation = (
    page: ActivePage,
    certAssistData?: { locationCode?: string },
    vehicleData?: Vehicle,
    certData?: any,
    resetSuccessMessage: boolean = false
  ) => {
    if (resetSuccessMessage) {
      setSuccessMessage(null);
    }
    setActivePage(page);
    if (certAssistData) {
      setCertAssistData(certAssistData);
    }
    if (vehicleData) {
      setVehicleData(vehicleData);
    }
    if (certData) setCertificationData(certData);
    if (page === 'HOME') store.dispatch(resetVehicle());
  };

  const handleSubmit = async (
    locationCode: string,
    workOrder: string,
    isContinueFromModal?: boolean
  ) => {
    setLoading(true);
    try {
      let vehicle: Vehicle | undefined;
      if (isContinueFromModal && vehicleData) {
        vehicle = vehicleData;
      } else {
        store.dispatch(resetVehicle());
        vehicle = await store
          .dispatch(fetchVehicle({ location: locationCode, workOrder }))
          .unwrap();
      }
      const certData = await fetchCertificationData(
        vehicle?.consignmentId || ''
      );
      const parsedCertification =
        typeof certData.certification === 'string'
          ? JSON.parse(certData.certification)
          : certData.certification;
      if (vehicle?.status === STRING_CONSTANTS.CHECKED_OUT)
        setVehicleData(vehicle);
      if (
        vehicle?.status !== STRING_CONSTANTS.CHECKED_OUT ||
        isContinueFromModal
      ) {
        handleNavigation(
          'TABS',
          { locationCode },
          vehicle,
          {
            processStatus: certData.processStatus,
            certification: parsedCertification
          },
          true
        );
      }
    } catch (error) {
      console.error('Failed to load data:', error);
      handleNavigation('HOME', undefined, undefined, false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Provider store={store}>
      <InterstateThemeProvider
        themeName='Interstate'
        applicationName={STRING_CONSTANTS.APP_NAME}
        scopeName='scope of your application'
        themeRegistries={[interstateThemeRegistry]}
      >
        {loading && (
          <Interstitial
            data-testid='loading'
            fullScreen={true}
            size={2}
            message='Loading Certification App'
          />
        )}
        <Suspense fallback={<div>Loading...</div>}>
          {activePage === 'HOME' && (
            <Home
              {...props}
              onSubmit={handleSubmit}
              successMessage={successMessage}
            />
          )}
          {activePage === 'TABS' && (
            <TabsView
              {...props}
              locationCode={certAssistData.locationCode}
              navigateToHome={() => handleNavigation('HOME')}
              vehicleData={vehicleData}
              setSuccessMessage={setSuccessMessage}
              initialCertificationData={certificationData}
            />
          )}
        </Suspense>
      </InterstateThemeProvider>
    </Provider>
  );
};

export default CertAssistApp;
