import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchOption } from '@interstate/components/SearchInput';

import { LocalStorageKeys, STRING_CONSTANTS } from 'constants/constants';

import ErrorMessageBar from 'reusableComponents/messages/errorMessageBar/ErrorMessageBar';
import ConfirmationModal from 'reusableComponents/confirmationModal/ConfirmationModal';

import { getRecentScansForUser } from 'store/slice/recentScans/recentScansSlice';
import { AppDispatch, RootState } from 'store/store';
import { fetchLocation } from 'store/slice/locations/locationSlice';

import { MFPayload } from 'models/MFPayload';
import { ScanResult } from 'models/ScanResult';
import { Snackbar } from '@interstate/components/Snackbar';

import {
  AlertBoxText,
  AlertBoxWarningMessage,
  ConfirmationModalAlert,
  ConfirmationModalContainer,
  HomePage
} from './HomeStyle';
import { RecentScansUI, ScannerUI } from '@checkpoint/common-components';

type HomeProps = MFPayload & {
  onSubmit: (
    locationCode: string,
    workOrder: string,
    isContinueFromModal?: boolean
  ) => void;
  successMessage?: string | null;
};

const Home: React.FC<HomeProps> = ({ onSubmit, successMessage, ...props }) => {
  STRING_CONSTANTS.HOST_PROXY_URL = props.appConfig?.proxyUrl ?? '';

  // Using useState hook to manage state for selected location, work order input, and location options
  const [selectedLocation, setSelectedLocation] = useState('');
  const [workOrder, setWorkOrder] = useState('');
  const [showError, setShowError] = useState<string | null>(null);
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [locationCode, setLocationCode] = useState('');

  const dispatch: AppDispatch = useDispatch();

  // 'RootState' is the type for the entire Redux state, and 'state.auctionLocation' accesses the specific slice of state
  const LocationState = useSelector((state: RootState) => state.location);
  const { data, error } = useSelector((state: RootState) => state.vehicle);

  const recentScansOfUser = useSelector(
    (state: RootState) => state.recentScans?.data?.RecentScans
  );

  const userId = props.userDetails?.userId;

  useEffect(() => {
    document.addEventListener('onScanComplete', ((e: CustomEvent<ScanResult>) =>
      handleOnScanComplete(e.detail)) as EventListener);

    return () => {
      document.removeEventListener('onScanComplete', ((
        e: CustomEvent<ScanResult>
      ) => handleOnScanComplete(e.detail)) as EventListener);
    };
    // eslint-disable-next-line
  }, []);

  const handleOnScanComplete = (event: ScanResult) => {
    setWorkOrder(event.result);
  };

  const handleScan = () => {
    if (props.openScanner) {
      props.openScanner(); // Ensure that this function is called
    } else {
      console.error('openScanner function not provided in props.');
    }
  };

  useEffect(() => {
    dispatch(fetchLocation());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Retrieve saved location from local storage
    const savedLocation = localStorage.getItem(LocalStorageKeys.LOCATION);
    if (savedLocation) {
      setSelectedLocation(savedLocation);

      const parts = savedLocation.split(' - ');
      const code = parts.length > 1 ? parts[1] : '';
      setLocationCode(code);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(getRecentScansForUser({ userId: userId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (LocationState.data?.locations) {
      const transformedData = LocationState.data.locations.map((item) => ({
        label: `${item.locationName} - ${item.locationCode}`
      }));
      setOptions(transformedData);
    }
  }, [LocationState?.data]);

  useEffect(() => {
    if (data?.status === STRING_CONSTANTS.CHECKED_OUT) setShowModal(true);
  }, [data]);

  useEffect(() => {
    if (error) {
      setShowError(error);
    }
  }, [error]);

  function handleLocationChange(value: string) {
    setSelectedLocation(value);

    // Extract locationCode
    const parts = value.split(' - ');
    const code = parts.length > 1 ? parts[1] : '';
    setLocationCode(code);
    localStorage.setItem(LocalStorageKeys.LOCATION, value);
  }

  function handleWOChange(event: any) {
    const inputValue = event?.target?.value;
    setWorkOrder(inputValue);
  }

  const submitForm = (loc: string, workOrderNumber: string) => {
    setShowError(null);
    onSubmit(loc, workOrderNumber);
  };

  const handleModalHideAction = () => {
    setShowModal(false);
  };

  const handleModalContinueAction = () => {
    if (data !== null) {
      setShowModal(false);
      onSubmit(locationCode, workOrder, true);
    }
  };

  const recentScanClickCallback = (
    locationCode: string,
    workOrderNumber: string
  ) => {
    setLocationCode(locationCode);
    setWorkOrder(workOrderNumber);
    submitForm(locationCode, workOrderNumber);
  };

  const formattedRecentScansList = () => {
    let recentScans: any[] = [];

    recentScansOfUser?.forEach((scan) => {
      const recentScan = JSON.parse(scan?.scanData);

      if (recentScan !== undefined && recentScan !== null) {
        recentScans.push({
          workOrderNumber: recentScan.workOrderNumber,
          locationCode: recentScan.locationCode,
          vin: recentScan.vin,
          shortDescription: recentScan.shortDescription,
          interiorColor: recentScan.interiorNormalizedName,
          exteriorColor: recentScan.exteriorNormalizedName,
          status: recentScan.status,
          imageHref: recentScan.imageHref
        });
      }
    });

    return recentScans;
  };

  return (
    <HomePage>
      {successMessage && (
        <Snackbar
          data-testid='home-success-snackbar'
          show={successMessage !== null}
          message={successMessage}
          position='top-center'
          type='success'
        />
      )}
      {showError && <ErrorMessageBar message={showError} />}

      <ScannerUI
        locationOptions={options}
        selectedLocation={selectedLocation}
        workOrder={workOrder}
        onLocationChange={handleLocationChange}
        onWorkOrderChange={handleWOChange}
        onScan={handleScan}
        onSubmit={() => {
          submitForm(locationCode, workOrder);
        }}
        isSubmitDisabled={
          !(workOrder!.length === 7 && selectedLocation.length !== 0)
        }
      />

      <RecentScansUI
        recentScansList={formattedRecentScansList()}
        callbackOnRecentScanClick={(
          locationCode: string,
          workOrder: string
        ): void => {
          recentScanClickCallback(locationCode, workOrder);
        }}
      />

      <ConfirmationModal
        modalBody={<ConfirmationModalBody />}
        confirmationText={STRING_CONSTANTS.CONTINUE}
        cancelText={STRING_CONSTANTS.BACK}
        showModal={showModal}
        onContinue={handleModalContinueAction}
        onCancel={handleModalHideAction}
      />
    </HomePage>
  );
};

const ConfirmationModalBody: React.FC = () => {
  return (
    <ConfirmationModalContainer
      className='confirmationModal-content'
      data-testid='confirmation-modal-body'
    >
      <ConfirmationModalAlert
        type='error'
        title={STRING_CONSTANTS.VEHICLE_CHECKEDOUT}
      >
        <AlertBoxText variant='body-md'>
          {STRING_CONSTANTS.VEHICLE_CHECKEDOUT_DESC}
        </AlertBoxText>
        <AlertBoxWarningMessage variant='body-md'>
          {STRING_CONSTANTS.VEHICLE_CHECKEDOUT_MSG}
        </AlertBoxWarningMessage>
      </ConfirmationModalAlert>
    </ConfirmationModalContainer>
  );
};

export default Home;
