import axios from 'axios';
import { getBaseUrl } from 'utils/configLoader';

export const fetchCertificationData = async (consignmentId: string) => {
  try {
    const response = await axios.get(
      `${getBaseUrl()}/certification/${consignmentId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status !== 401) {
      return {
        certification: {
          startTime: new Date().toISOString(),
          qnaData: { questionResponses: [] }
        },
        processStatus: null
      };
    } else if (axios.isAxiosError(error)) {
      throw new Error(
        `API Error: ${error.response?.data?.message || error.message}`
      );
    } else {
      throw new Error(`Unexpected Error: ${error}`);
    }
  }
};

export const postCertificationData = async (certData: any) => {
  try {
    const response = await axios.post(
      `${getBaseUrl()}/certification`,
      certData,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        `API Error: ${error.response?.data?.message || error.message}`
      );
    } else {
      throw new Error(`Unexpected Error: ${error}`);
    }
  }
};
