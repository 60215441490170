import React from 'react';

import { AlertBox, AlertBoxText } from './AlertComponentStyle';
import { Alert } from '@interstate/components/Alert';

export enum AlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Caution = 'caution'
}

interface AlertComponentProps {
  title: string;
  subtitle: string;
  alertSeverity: AlertSeverity;
  testId: string;
}

export const AlertComponent: React.FC<AlertComponentProps> = (props) => {
  return (
    <AlertBox>
      <Alert title={props.title} type={props.alertSeverity}>
        <AlertBoxText variant={'body-md'} data-testid={props.testId}>
          {props.subtitle}
        </AlertBoxText>
      </Alert>
    </AlertBox>
  );
};

export default AlertComponent;
